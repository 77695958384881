import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { first, map, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  MyMessageService,
  PaginatedResponse,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { SearchesService } from '../../../services/searches.service';
import { PaidEducationalLeaveAttestListItem } from '../../../types/paid-educational-leave.type';
import { BranchService } from '../../../services/branch.service';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import {
  PAID_EDUCATIONAL_LEAVE_ACTION,
  PAID_EDUCATIONAL_LEAVE_ATTEST_STATE,
  PAID_EDUCATIONAL_LEAVE_TYPE
} from '../../../types/paid-educational-leave.enum';
import { PaidEducationalLeaveService } from '../../../services/paid-educational-leave.service';
import {
  PaidEducationalLeaveActionDialogComponent
} from '../../components/paid-educational-leave-action-dialog/paid-educational-leave-action-dialog.component';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-list',
  templateUrl: './paid-educational-leave-list.component.html'
})
export class PaidEducationalLeaveListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<PaidEducationalLeaveAttestListItem>;
  searchFilters: SearchFilter[];
  canCommunicate = false;
  canApprove = false;

  currentAction: PAID_EDUCATIONAL_LEAVE_ACTION;
  private _subscription: Subscription;
  private currentSelection: PaidEducationalLeaveAttestListItem[] = [];

  protected readonly PAID_EDUCATION_LEAVE_ACTION = PAID_EDUCATIONAL_LEAVE_ACTION;

  @ViewChild(PaidEducationalLeaveActionDialogComponent)
  paidEducationalLeaveActionDialog: PaidEducationalLeaveActionDialogComponent;

  @ViewChild(SearchComponent)
  searchComponent: SearchComponent;

  constructor(private searchesService: SearchesService,
              private paidEducationalLeaveService: PaidEducationalLeaveService,
              private referenceDataService: ReferenceDataService,
              private translationService: TranslationService,
              private branchService: BranchService,
              private messageService: MyMessageService) {
  }

  ngOnInit(): void {
    this._subscription = this.translationService.languageChange$.subscribe(() =>
      this._createSearch()
    );
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    this.searchData$ = new InfiniteScrollDataAdapter<PaidEducationalLeaveAttestListItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<PaidEducationalLeaveAttestListItem>> => {
      return this.searchesService.searchPaidEducationalLeaveAttests(searchRequest);
    }, searchRequest, true);

    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.category',
        expanded: true,
        key: FilterType.filterRecognitionTypes,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES, FilterType.filterRecognitionTypes)
          .pipe(map(result => result.filter(filter => filter.value !== PAID_EDUCATIONAL_LEAVE_TYPE.INTERIM)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.schoolYear',
        key: FilterType.filterSchoolYears,
        data: this.referenceDataService.getPaidEducationalLeaveSchoolYearsFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.status',
        expanded: true,
        key: FilterType.filterStatuses,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_STATUSES, FilterType.filterStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enrollments.workStatus',
        expanded: false,
        key: FilterType.filterWorkStatuses,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.WORK_STATUSES, FilterType.filterWorkStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.region',
        expanded: false,
        key: FilterType.filterRegions,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.REGIONS, FilterType.filterRegions)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.mentor',
        expanded: false,
        key: FilterType.filterMentorTraining,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterMentorTraining)
      },
      {
        type: SearchFilterType.lazyLoadItem,
        key: FilterType.filterBranches,
        label: 'paidEducationalLeaves.branches',
        expanded: false,
        dataSource: (searchRequest: SearchRequest) => {
          return this.searchesService.searchBranches(searchRequest)
            .pipe(map((result: PaginatedResponse<any>) => {
              result.data = result.data.map(item => ({
                value: '' + item.branchId,
                label: item.name,
                type: FilterType.filterBranches
              }));
              return result;
            }));
        },
        getItem: (id: string) => this.branchService.getBranch(id)
          .pipe(map(branch => ({
            value: '' + branch.branchId,
            label: branch.branchName,
            type: FilterType.filterBranches
          }) as IFilter))
      }
    ];
  }

  onselectionchange(event: PaidEducationalLeaveAttestListItem[]): void {
    this.currentSelection = event;
    this.canCommunicate = event.length > 0 &&
      event.every(paidEducationalLeave => paidEducationalLeave.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV) &&
      event.every(paidEducationalLeave => [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.COMMUNICATION_SEND, PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.VALIDATED].includes(paidEducationalLeave.stateId));
    this.canApprove = event.length > 0 &&
      event.every(paidEducationalLeave => paidEducationalLeave.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV) &&
      event.every(paidEducationalLeave => [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.COMMUNICATION_SEND, PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.VALIDATED].includes(paidEducationalLeave.stateId));
  }

  executeAction(event: any): void {
    event.setLoading(true);
    let request$;
    if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.ATTEST_REQUESTED) {
      request$ = this.paidEducationalLeaveService.requestAttestNeeded(this.currentSelection.map(item => item.id));
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.CREATE_AND_SEND) {
      request$ = this.paidEducationalLeaveService.createAndSendAttest(this.currentSelection.map(item => item.id));
    }

    request$.pipe(first()).subscribe({
      next: () => {
        this.messageService.success('communicationsSend');
        event.setLoading(false);
        this.paidEducationalLeaveActionDialog.closeDialog();
        this.currentSelection = [];
        this.searchComponent.removeSelection();
        this.searchComponent.refresh();
      },
      error: () => {
        event.setLoading(false);
      }
    });
  }

  openActionDialog(action: PAID_EDUCATIONAL_LEAVE_ACTION): void {
    this.currentAction = action;
    this.paidEducationalLeaveActionDialog.open();
  }
}

<div>
  <div class='max-w-[900px]'>
    <div *ngFor='let training of data'>
      <div class='flex justify-between items-center gap-4 hover:bg-primary-hover cursor-pointer p-2'
           (click)='collapsed[training.trainingId] = !collapsed[training.trainingId]'>
        <div class='flex items-center gap-6'>
          <i class="pi pi-angle-{{ !collapsed[training.trainingId] ? 'up' : 'down' }}"></i>
          <a class='text-primary hover:underline cursor-pointer font-bold' (click)='onTrainingClick(training, $event)'>
            {{ training.alimentoId }} - {{ training.title }} <span class='font-normal text-text'>({{ training.sessions.length }})</span>
          </a>

          <span>{{"trainings.sessions.totalDuration" | translate}} {{ training.totalHours | duration }}</span>
          <span *ngIf='training.mentor'>{{ 'paidEducationalLeaves.mentor' | translate }}</span>
          <a class='flex items-center gap-2 text-primary cursor-pointer hover:underline'
             *ngIf='training.documentSetUrl' href='{{training.documentSetUrl}}' target='_blank'>
            <i class='font-material'>open_in_new</i>
            {{ 'documents' | translate }}
          </a>
        </div>
        <div class='flex gap-2 items-center justify-between'>
          <span (click)='trainingValidatedToggle.writeValue(!trainingValidatedToggle.checked()); $event.stopImmediatePropagation()'
                (keyup.enter)='trainingValidatedToggle.writeValue(!trainingValidatedToggle.checked()); $event.stopImmediatePropagation()'
                class='focus-visible:outline-0 focus-visible:underline'
                tabindex='0'
          >
            {{ 'paidEducationalLeaves.actions.validated' | translate }}
          </span>
          <p-inputSwitch #trainingValidatedToggle [(ngModel)]='training.validated'
                         (click)='$event.stopImmediatePropagation()'
                         (onChange)='trainingToggled(training.trainingId, trainingValidatedToggle.checked())'></p-inputSwitch>
        </div>
      </div>

      <div *ngIf='!collapsed[training.trainingId]'>
        <div *ngFor='let session of training.sessions' class='flex items-center gap-4 justify-between ml-8 p-2'>
          <div class='grid grid-cols-[100px_1fr] items-center gap-8'>
            <span>{{ session.registeredDate | date: "dd/MM/yyyy" }}</span>
            <span>{{ session.registeredHours | duration }}</span>
          </div>
          <div class='flex gap-2 items-center justify-between'>
            <span (click)='sessionValidatedToggle.writeValue(!sessionValidatedToggle.checked()); $event.stopImmediatePropagation()'
                  (keyup.enter)='sessionValidatedToggle.writeValue(!sessionValidatedToggle.checked()); $event.stopImmediatePropagation()'
                  class='focus-visible:outline-0 focus-visible:underline'
                  tabindex='0'
            >
              {{ 'paidEducationalLeaves.actions.validated' | translate }}
            </span>
            <p-inputSwitch #sessionValidatedToggle
                           [(ngModel)]='session.validated'
                           (onChange)='sessionToggled(training.trainingId, session.id, sessionValidatedToggle.checked())'></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  LeaveConfirmService,
  MyMessageService,
  NOTES_TYPE,
  NotesSidebarComponent,
  Role,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { first, forkJoin, Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PaidEducationalLeaveAttest,
  PaidEducationalLeaveContactEvent,
  paidEducationalLeaveHourUpdateEvent
} from '../../../types/paid-educational-leave.type';
import { PaidEducationalLeaveService } from '../../../services/paid-educational-leave.service';
import { PersonService } from '../../../services/person.service';
import { BranchService } from '../../../services/branch.service';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { Person } from '../../../types/person.type';
import { Branch, ContactPerson } from '../../../types/branch.type';
import { RESPONSIBILITY } from '../../../types/branch.enum';
import { EmploymentService } from '../../../services/employment.service';
import { CommunicationsService } from '../../../services/communications.service';
import { MailContextType } from '../../../types/communications.enum';
import { Location } from '@angular/common';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-detail',
  templateUrl: './paid-educational-leave-detail.component.html'
})
export class PaidEducationalLeaveDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  paidEducationalLeaveId: string;
  paidEducationalLeaveAttest: PaidEducationalLeaveAttest;

  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  readOnly = false;
  showNotes = false;
  notesHasChanges: boolean;

  private _subscriptions: Subscription[] = [];
  type: string;
  person: Person;
  branch: Branch;
  contactPersons: ContactPerson[];
  schoolYear: string;
  workStatus: string;
  PaidEducationalLeaveNote = NOTES_TYPE.PaidEducationalLeaveNote;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  protected readonly MailContextType = MailContextType;
  private _getCommunicationCountSub: Subscription;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private authorizationService: AuthorizationService,
    private paidEducationalLeaveService: PaidEducationalLeaveService,
    private personService: PersonService,
    private referenceDataService: ReferenceDataService,
    private branchService: BranchService,
    private employmentService: EmploymentService,
    private messageService: MyMessageService,
    private leaveConfirmationService: LeaveConfirmService,
    private communicationService: CommunicationsService,
    private location: Location
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.paidEducationalLeaveId = params['paidEducationalLeaveId'];
        if (this.paidEducationalLeaveId) {
          this._fetchData();
        }
        else {
          this._navigateToErrorPage();
        }
      });

    this.route.queryParams.subscribe(
      (queryParams) => (this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0)
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._getCommunicationCountSub?.unsubscribe();
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  private _fetchData(): void {
    this.paidEducationalLeaveService.getpaidEducationalLeave(this.paidEducationalLeaveId)
      .pipe(first())
      .subscribe({
        next: paidEducationalLeave => {
          this.paidEducationalLeaveAttest = paidEducationalLeave;

          forkJoin([
            this.personService.getPerson(this.paidEducationalLeaveAttest.personId).pipe(first()),
            this.branchService.getBranch(this.paidEducationalLeaveAttest.branchId).pipe(first()),
            this.branchService.getContactPersons(this.paidEducationalLeaveAttest.branchId).pipe(first()),
            this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.typeId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES).pipe(first())
          ])
            .subscribe(([person, branch, contactPersons, type]) => {
              this.person = person;
              this.branch = branch;
              this.type = type.label;
              this.contactPersons = contactPersons.filter(contactPerson =>
                contactPerson.responsibilities.map(responsibility => responsibility.data).includes(RESPONSIBILITY.BEV));
              this.titleService.setTitle([this.type, this.paidEducationalLeaveAttest.alimentoId + '']);
              this._createMenuItems();

              this.schoolYear = this.paidEducationalLeaveAttest.schoolYear + '-' + (this.paidEducationalLeaveAttest.schoolYear + 1);

              this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.workStatusId, OPTIONS_LIST_TYPE.WORK_STATUSES)
                .pipe(first())
                .subscribe(workStatus => this.workStatus = workStatus.label);
            });
        },
        error: () => {
          this._navigateToErrorPage();
        }
      });
  }

  private _navigateToErrorPage(): void {
    this.router.navigate(['error'], {
      state: {
        message: this.translateService.instant('error.itemWithIdDoesNotExist', {
          item: this.translateService.instant('paidEducationalLeaves.menuTitle'),
          id: this.paidEducationalLeaveId
        }),
        redirectUrl: '/'
      }
    });
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('paidEducationalLeaves.basicData') }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('branches.notesTitle'),
            disabled: !this.paidEducationalLeaveId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          }
        ];
      })
    );
  }

  sessionUpdateEvent(event: paidEducationalLeaveHourUpdateEvent): void {
    this.paidEducationalLeaveService.updatepaidEducationalLeaveHours(event.paidEducationalLeaveAttestId, event.hours as any).pipe(first())
      .subscribe({
        next: () => {
          if (this.contactPersons?.length > 0 && event.allValidated) {
            this._fetchData();
          }
        }
      });
  }

  updateContactPerson(event: PaidEducationalLeaveContactEvent): void {
    event.setLoading(true);
    this.employmentService.getEmploymentsOfPersonByBranch(event.branchId, event.contactPersonId).pipe(first())
      .subscribe({
        next: employment => {
          const employmentData = employment as any;
          employmentData.responsibilityIds = (employment.responsibilities || []).map(responsibility => responsibility.data);
          employmentData.responsibilityIds.push(RESPONSIBILITY.BEV);
          delete employmentData.responsibilities;
          employmentData.workStatusId = employment.workStatus?.data;
          delete employmentData.workStatus;

          this.employmentService.updateEmployment(event.contactPersonId, employment.employmentId, employmentData)
            .pipe(first())
            .subscribe({
              next: () => {
                event.setLoading(false);
                this.messageService.success();
                event.closeDialog();

                this._fetchData();
              },
              error: () => event.setLoading(false)
            });
        },
        error: () => event.setLoading(false)
      });
  }

  private _setDetailUrl(): void {
    if (this.paidEducationalLeaveId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/paid-educational-leaves', this.paidEducationalLeaveId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }
}

export enum TaskState {
  Open = '4d16ed0a-6b5b-4d66-b474-e735378dff77',
  Complete = '6b198ebc-2e3d-4a44-b4a8-3f71575ad014'
}

export enum MailLogItemState {
  Pending = '4a516fff-f38b-4de5-adab-28846656bf25',
  Processing = 'fe309005-6632-40c3-bdbc-77e2b80559d2',
  Success = 'bd57f272-7729-41a5-ba74-c63a7d526e51',
  Failed = '0ccdd936-6772-44f6-9c5e-891b8cc8f91d'
}

export enum MailLogState {
  Pending = '29155cde-6918-4312-92e3-b6ec8105ce75',
  Failed = 'a8f74016-5e56-4fb8-b743-a2e8b4851420',
  Submitted = '8e75891b-7d03-4c13-9f8c-41edd99c3039'
}

export const MAIL_STATE_FIXED = "Fixed";

export enum MailContextType {
  Training = '2d5e6fdc-94c1-48c2-80e4-045abccc4dc0',
  Enrollment = '019d741c-d645-43e2-acb4-5b7a5f819fe0',
  Session = '2537c31b-9373-4124-b177-9737b0264cd7',
  Branch = '80602ee0-e265-4f5b-94af-451dc657f1c5',
  PaidEducationalLeave = '7457f12d-76f7-40f4-8ff6-3b40b5dc0a95'
}

export const COMMUNICATION_TO_DO = "4d16ed0a-6b5b-4d66-b474-e735378dff77";

export const getMailLogStateKey = (status: MailLogState): string => {
  return Object.keys(MailLogState).filter(
    (key) => (MailLogState as { [key: string]: string })[key] === status
  )[0];
};

export const getMailLogItemStateKey = (status: MailLogItemState): string => {
  return Object.keys(MailLogItemState).filter(
    (key) => (MailLogItemState as { [key: string]: string })[key] === status
  )[0];
};

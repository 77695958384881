<p-dropdown
  [filter]="true"
  [filterFields]='["email", "lastName", "firstName", "lastNameFullIndex", "firstNameFullIndex"]'
  [options]="contactPersonSource"
  appendTo="body"
  [formControl]='formControl'
  id="enrollment.contactPerson"
  optionLabel="lastName"
  [optionValue]="isBranchOnlyContactPerson ? 'personId' : 'employmentId'"
  styleClass="w-full"
  placeholder="{{ 'enrollments.chooseOption' | translate }}"
  [showClear]="true"
>
  <ng-template let-contactPerson pTemplate="selectedItem">
    <div *ngIf='contactPerson'>
      <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
        <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
            {{"trainings.basicData.inactive" | translate}}
          </span>
        </span>
    </div>
  </ng-template>
  <ng-template let-contactPerson pTemplate="item">
    <div *ngIf='contactPerson'
         class='grid {{showBranchInfo ? "grid-cols-[250px_300px_300px_300px]" : "grid-cols-[250px_300px_300px]"}} gap-4'>
      <span class='whitespace-pre-wrap'>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
        <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
          {{"trainings.basicData.inactive" | translate}}
        </span>
      </span>
      <span class='whitespace-pre-wrap'>{{contactPerson.email}}</span>
      <span *ngIf='showBranchInfo' class='whitespace-pre-wrap'>{{contactPerson.branchName}} - {{contactPerson.branchAddress}}</span>
      <div class='whitespace-pre-wrap'>
        <span *ngFor='let responsibility of contactPerson.responsibilities; let last = last'>{{responsibility.label}}{{!last ? ', ' : ''}}</span>
      </div>
    </div>
  </ng-template>
</p-dropdown>

<div *ngIf='formControl.value && showSelectedPerson' class='conditional-fields pb-4'>
  <div class='flex items-center gap-1'>
    <span *ngIf='showLabels'>{{'persons.email' | translate}}:</span>
    <span>{{selectedContactPerson().email}}</span>
  </div>
  <div class='flex items-center gap-1' *ngIf='showBranchInfo'>
    <span *ngIf='showLabels'>{{'persons.employments.branch' | translate}}:</span>
    <span>{{selectedContactPerson().branchName}} - {{selectedContactPerson().branchAddress}}</span>
  </div>
  <div class='flex items-center gap-1'>
    <span *ngIf='showLabels'>{{'persons.employments.responsibilities' | translate}}:</span>
    <span *ngFor='let responsibility of selectedContactPerson().responsibilities; let last = last'>
      {{responsibility.label}}{{!last ? ', ' : ''}}
    </span>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  GroupedPaidEducationalLeaveHours,
  PaidEducationalLeaveAttest,
  paidEducationalLeaveHourUpdateEvent
} from '../../../types/paid-educational-leave.type';
import { SimpleTraining } from '../../../types/training.type';
import { TrainingService } from '../../../services/training.service';
import { first, forkJoin } from 'rxjs';
import { PAID_EDUCATIONAL_LEAVE_HOURS_STATE } from '../../../types/paid-educational-leave.enum';
import { TrainingMapper } from '../../../utils/mapper/training.mapper';
import { Router } from '@angular/router';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-sessions',
  templateUrl: './paid-educational-leave-sessions.component.html'
})
export class PaidEducationalLeaveSessionsComponent implements OnChanges {
  @Input()
  paidEducationalLeaveAttest: PaidEducationalLeaveAttest;

  trainings: SimpleTraining[];

  data: GroupedPaidEducationalLeaveHours[] = [];
  collapsed: {[key: string]: boolean} = { };

  @Output()
  sessionUpdateEvent: EventEmitter<paidEducationalLeaveHourUpdateEvent> = new EventEmitter();

  constructor(private trainingService: TrainingService,
              private trainingMapper: TrainingMapper,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paidEducationalLeaveAttest']?.currentValue) {
      this._getAllTrainings();
    }
  }

  private _getAllTrainings(): void {
    forkJoin(this.paidEducationalLeaveAttest.paidEducationalLeaveHours
      .map(session => session.trainingId)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(trainingId => this.trainingService.getSimpleTraining(trainingId).pipe(first())))
      .subscribe(trainings => {
        this.trainings = trainings;
        this.data = [];
        trainings
          .sort((a, b) => (new Date(a.startDate).getTime() - new Date(b.startDate).getTime()))
          .forEach(training => {
            const sessions = this.paidEducationalLeaveAttest.paidEducationalLeaveHours
              .filter(session => session.trainingId === training.trainingId);
            sessions.forEach(session => {
              (session as any).validated = session.paidEducationalLeaveHourStatusId === PAID_EDUCATIONAL_LEAVE_HOURS_STATE.VALIDATED;
            });

            const validated = sessions.every(session => session.paidEducationalLeaveHourStatusId !== PAID_EDUCATIONAL_LEAVE_HOURS_STATE.PROPOSED) &&
              sessions.some(session => session.paidEducationalLeaveHourStatusId === PAID_EDUCATIONAL_LEAVE_HOURS_STATE.VALIDATED);

            const timeMinutes = sessions.filter(session => session.registeredHours)
              .reduce((timeMinutes, session) => {
                function getTimeMinutes(duration: string) {
                  const split = duration.split(':');
                  return Number(split[1]) + (Number(split[0]) * 60)
                }
                return timeMinutes + getTimeMinutes(session.registeredHours);
              }, 0);

            this.data.push({
              trainingId: training.trainingId,
              alimentoId: training.alimentoId,
              trainingType: training.typeId,
              title: training.customTitle,
              sessions: (sessions as any[]).sort((a, b) => new Date(a.registeredDate).getTime() - new Date(b.registeredDate).getTime()),
              totalHours: ("" + Math.floor(timeMinutes / 60)).padStart(2, "0") + ':' + ("" + Math.floor(timeMinutes % 60)).padStart(2, "0"),
              mentor: training.mentorTraining,
              documentSetUrl: training.documentSetUrl,
              validated: validated,
            });
            this.collapsed[training.trainingId] = validated;
          });
      });
  }

  trainingToggled(trainingId: string, checked: boolean): void {
    const sessions = this._getTraining(trainingId).sessions
      .filter(session => session.validated !== checked);
    sessions.forEach(session => session.validated = checked);

    this.sessionUpdateEvent.emit({
      paidEducationalLeaveAttestId: this.paidEducationalLeaveAttest.id,
      hours: sessions.map(session => ({
        id: session.id,
        paidEducationalLeaveHourStatusId: checked ? PAID_EDUCATIONAL_LEAVE_HOURS_STATE.VALIDATED : PAID_EDUCATIONAL_LEAVE_HOURS_STATE.PROPOSED
      })),
      allValidated: this.data.every(training => training.validated)
    });
  }

  sessionToggled(trainingId: string, hoursId: string, checked: boolean): void {
    const training = this._getTraining(trainingId);
    training.validated = training.sessions.every(session => session.validated);

    this.sessionUpdateEvent.emit({
      paidEducationalLeaveAttestId: this.paidEducationalLeaveAttest.id,
      hours: [{
        id: hoursId,
        paidEducationalLeaveHourStatusId: checked ? PAID_EDUCATIONAL_LEAVE_HOURS_STATE.VALIDATED : PAID_EDUCATIONAL_LEAVE_HOURS_STATE.PROPOSED
      }],
      allValidated: this.data.every(training => training.validated)
    });
  }

  private _getTraining(trainingId: string): GroupedPaidEducationalLeaveHours {
    return this.data.filter(training => training.trainingId === trainingId)[0];
  }

  onTrainingClick(training: GroupedPaidEducationalLeaveHours, event: MouseEvent): void {
    event.stopImmediatePropagation();
    const path = this.trainingMapper.getNavigationPath(training.trainingId, training.trainingType);
    if (event.ctrlKey) {
      window.open(path.join('/'), '_blank');
    }
    else {
      this.router.navigate(path);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import {
  ReimbursementRequestListComponent
} from './pages/reimbursement-request-list/reimbursement-request-list.component';
import {
  ReimbursementRequestDetailComponent
} from './pages/reimbursement-request-detail/reimbursement-request-detail.component';
import { ReimbursementRequestComponent } from './components/reimbursement-request/reimbursement-request.component';
import { UtilsModule } from '../utils/utils.module';
import { BranchesModule } from '../branches/branches.module';
import { PersonsModule } from '../persons/persons.module';
import { SessionsModule } from '../sessions/sessions.module';
import { EnrollmentsModule } from '../enrollments/enrollments.module';
import { TrainingsModule } from '../trainings/trainings.module';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    UiLibModule,
    UtilsModule,
    InputTextModule,
    ConfirmDialogModule,
    InputSwitchModule,
    MenuModule,
    RippleModule,
    CheckboxModule,
    BranchesModule,
    PersonsModule,
    SessionsModule,
    EnrollmentsModule,
    TrainingsModule,
    RadioButtonModule,
    InputTextareaModule
  ],
  providers: [ConfirmationService, CanDeactivateGuard],
  declarations: [
    ReimbursementRequestListComponent,
    ReimbursementRequestDetailComponent,
    ReimbursementRequestComponent
  ]
})
export class ReimbursementRequestsModule {
}

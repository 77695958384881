import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PAID_EDUCATIONAL_LEAVE_ACTION } from '../../../types/paid-educational-leave.enum';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-action-dialog',
  templateUrl: './paid-educational-leave-action-dialog.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PaidEducationalLeaveActionDialogComponent {

  @Input()
  action: PAID_EDUCATIONAL_LEAVE_ACTION;

  @Output()
  submitPopup = new EventEmitter<any>();

  dialogVisible = false;
  loading = false;

  open(): void {
    this.dialogVisible = true;
  }

  onSubmit(): void {
    this.submitPopup.emit({
      action: this.action,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }
}

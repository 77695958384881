<alimento-ipv-frontend-complete-task-dialog
  #completeTaskDialog
  (submitPopup)='completeTasks($event, completeTaskDialog, searchCommunications)'>
</alimento-ipv-frontend-complete-task-dialog>

<alimento-ipv-frontend-header
  title="{{ 'trainings.communications.communications' | translate }}"
></alimento-ipv-frontend-header>

<div class='mt-4 {{showFilters ? "-ml-4" : ""}}'>
  <lib-search #searchCommunications
              [beforeSearchRequest]='beforeSearchRequest'
              [cardTemplate]='cardTemplate'
              [data$]='searchData$'
              [filters]='showFilters ? searchFilters : []'
              [showToggle]='showFilters'
              [expandAll]='false'
              [inlineFilters]='true'
              [showSearchFilter]='false'
              [showActiveFilters]='showFilters'
              [showCountTop]='showFilters'
              availableResultsTranslateKey='communications.menuTitle'
              name='all-communications'
              searchPlaceholderKey='communications.searchPlaceholder'
              toggleLabel='communications.toggle'
  >
    <ng-template #cardTemplate let-communication='item'>
      <alimento-ipv-frontend-communication-card
        [communication]='communication'
        [readOnly]='readOnly'
        (completeTaskClicked)='completeTaskDialog.open($event)'
        tabindex='0'
      ></alimento-ipv-frontend-communication-card>
    </ng-template>
  </lib-search>
</div>

<div class="flex flex-col">
  <span class="text-[10px] text-grey-5 uppercase overflow-hidden block text-ellipsis">
    {{ (title || "") | translate }}
  </span>
  <ng-content></ng-content>
  <div class="w-full text-text text-[16px] whitespace-pre-line flex flex-wrap gap-2 {{cardLayout? 'leading-4' : 'leading-6'}}">
    <div *ngFor='let value of (values || [])' class='w-full whitespace-pre-line'>
      <a *ngIf='isLink'
         class='text-primary hover:underline cursor-pointer'
         [routerLink]='value.link || []'>
        {{value.label}}
      </a>
      <span class='break-words block' *ngIf='!isLink'>{{value.label}}</span>
    </div>
  </div>
</div>



import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ALIMENTO_EXCEPTIONS, ERRORS, EXCEPTIONS } from '../models/errors.enum';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {


  constructor(private messageService: MessageService, private readonly injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status !== 412) {
          this.handleError(error.error);
        }
        return throwError(error);
      })
    );
  }

  handleError(error: any): void {
    try {
      const translateService: TranslateService = this.injector.get(TranslateService);
      if (error?.type === EXCEPTIONS.business) {
        this.messageService.add({
          severity: 'error',
          detail: this._getBusinessErrorMessage(error, translateService),
          sticky: true
        });
      }
      else if (error?.type === EXCEPTIONS.validation) {
        if (error.errors) {
          for (const value of Object.values(error.errors || [])) {
            this.messageService.add({
              severity: 'error',
              detail: `${value}`,
              sticky: true
            });
          }
        }
        else {
          this.messageService.add({
            severity: 'error',
            detail: translateService.instant('validation.hasErrors'),
            sticky: true
          });
        }
      }
      else if (error?.type === EXCEPTIONS.multiple) {
        this._handleMultipleErrors(error);
      }
      else {
        this.messageService.add({
          severity: 'error',
          detail: this._getErrorMessage(error),
          sticky: true
        });
      }
    }
    catch {
      console.error('translateService not available');
    }
  }

  private _handleMultipleErrors(error: any): void {
    this.messageService.add({
      severity: 'error',
      data: {
        errors: error.errors.map((error: any) => this._getErrorMessage(error))
      },
      detail: 'test',
      key: 'multiple-errors',
      sticky: true
    });
  }

  private _getBusinessErrorMessage(error: any, translateService: TranslateService): string {
    let options = undefined;

    const message = error.title;
    if (error.title === ERRORS.FIELD_REQUIRED) {
      options = { field: error.field };
    }
    else if (error.title === ERRORS.FIELD_BETWEEN) {
      options = { field: error.field, min: error.minValue, max: error.maxValue };
    }
    else if (error.title === ERRORS.FIELD_LESS_THEN_FIELD) {
      options = { field: error.field, otherField: error.otherField };
    }

    return translateService.instant('error.' + message, options);
  }


  private _getErrorMessage(error: any): string {
    let detailMessage;
    try {
      const translateService: TranslateService = this.injector.get(TranslateService);
      const type = error?.type?.replace(new RegExp('^' + ALIMENTO_EXCEPTIONS), '');
      let options = undefined;

      if ([
        ERRORS.EOL_DEFAULT_ACCOUNT_NOT_FOUND,
        ERRORS.EOL_SUPPLIER_NOT_FOUND,
        ERRORS.SUPPLIER_NOT_ALLOWED
      ].includes(type)) {
        options = { supplier: error.data.supplierName, supplierId: error.data.supplierId };
      }
      else if ([
        ERRORS.EOL_BRANCH_NOT_FOUND,
        ERRORS.EOL_PERSON_NOT_FOUND
      ].includes(type)) {
        options = { clientName: error.data.clientName, clientId: error.data.clientId };
      }
      else if (type === ERRORS.EOL_PURCHASE_GLACCOUNT_NOT_FOUND || type === ERRORS.EOL_SALE_GLACCOUNT_NOT_FOUND) {
        options = { glAccount: error.data.glAccountCode };
      }
      else if (type === ERRORS.EOL_INVALID_REFERENCEYEAR) {
        options = { reportingYear: error.data.reportingYear };
      }
      else if (type === ERRORS.MUST_BE_GREATER_THAN) {
        options = { field: error.data.field, minValue: error.data.minValue };
      }
      detailMessage = translateService.instant('error.' + type, options);

      if (!detailMessage || detailMessage === 'error.' + type) {
        detailMessage = translateService.instant('error.somethingWentWrong');
      }
    }
    catch {
      console.error('translateService not available');
    }

    return detailMessage || error.detail;
  }
}

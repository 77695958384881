export enum PAID_EDUCATIONAL_LEAVE_ATTEST_STATE {
  PROPOSED = '015dabfb-8028-43a2-9248-79614bbdfaf0',
  VALIDATED = '76c03791-7c29-4b79-ae22-61d566cceb82',
  COMMUNICATION_SEND = 'ad83ca09-262e-4024-9b71-adc74c9afa5f',
  ATTESTED = "46726a4d-f6b3-4c51-bbd8-ca3ec57228b3",
  BLOCKED = "7e4a47d1-b245-4d5d-8357-62979b531905",
  NOT_NEEDED = "5bb30f07-f085-4949-9fd7-e7cc5fbeac7f"
}

export enum PAID_EDUCATIONAL_LEAVE_HOURS_STATE {
  VALIDATED = "eb2d3f57-3d78-409d-84ae-f6af7f32ccf4",
  PROPOSED = "01b507c1-f4a2-4999-9473-47d2e15f0550",
  ALREADY_USED = "acf0ebce-4c0e-4bce-bdcb-6699e06c8caf",
  NOT_NEEDED = "a34977ce-c6c9-4ede-9e01-23fd01bfee34",
}

export enum PAID_EDUCATIONAL_LEAVE_TYPE {
  BEV = "5a2c6fc6-87b0-4d43-8870-04a60d97a2d7",
  VOV = "88eb0ea9-7005-4739-95aa-2085fc5e0c30",
  INTERIM = "aa6c156c-2c7c-43f0-8bca-3d74dabe49ac"
}

export enum PAID_EDUCATIONAL_LEAVE_ACTION {
  ATTEST_REQUESTED = "attestRequested",
  CREATE_AND_SEND = "createAndSend"
}

<lib-lazy-dropdown
  *ngIf='formControl.enabled'
  [data$]='data$'
  [template]='itemTemplate'
  [formControl]='formControl'
  [showSearchIcon]='false'
  [showClear]='true'
  [displayValue]='getSelectedString'
  [width]='1000'
  [minHeight]='94'
  [maxHeight]='400'
  dropdownIcon='pi pi-search'
  placeholder="{{ placeholder | translate }}"
  field='trainingAllowanceApplicationId'
>
  <ng-template #itemTemplate let-item="item">
    <alimento-ipv-frontend-reimbursement-request-card [reimbursementRequest]='item'>
    </alimento-ipv-frontend-reimbursement-request-card>
  </ng-template>
</lib-lazy-dropdown>
<div *ngIf='formControl.disabled && formControl?.value'>
  <alimento-ipv-frontend-reimbursement-request-card [reimbursementRequest]='formControl.value'>
  </alimento-ipv-frontend-reimbursement-request-card>
</div>

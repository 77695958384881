
<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between">
    <h1 class="uppercase">{{ 'communications.menuTitle' | translate }}</h1>
  </div>

  <p-tabView [(activeIndex)]="activeIndex" (onChange)="changeTab($event)">
    <p-tabPanel >
      <ng-template pTemplate='header'>
        <span class='text-xl p-tabview-title'>{{'communications.todo' | translate}}</span>
        <span *ngIf="toDoCount > 0" class="bg-primary font-normal text-white ml-2 px-2">
            {{toDoCount}}
          </span>
      </ng-template>
      <ng-template pTemplate="content">
        <alimento-ipv-frontend-complete-task-dialog
          #completeTaskToDoDialog
          (submitPopup)='completeTasks($event, completeTaskToDoDialog, toDoCommunications)'>
        </alimento-ipv-frontend-complete-task-dialog>
        <div class='my-4'>
          <lib-search #toDoCommunications
                      [data$]='toDoSearchData$'
                      [filters]='toDOSearchFilters'
                      [cardTemplate]='cardTemplate'
                      [showToggle]='true'
                      [beforeSearchRequest]='beforeSearchRequest'
                      [isToggled]='true'
                      name='to-do-communications'
                      toggleLabel='communications.toggle'
                      searchPlaceholderKey='communications.searchPlaceholder'
                      availableResultsTranslateKey='communications.menuTitle'
          >
            <ng-template #cardTemplate let-communication="item">
              <alimento-ipv-frontend-communication-card
                [readOnly]='readOnly'
                [communication]='communication'
                (completeTaskClicked)='completeTaskToDoDialog.open($event)'
                [showNavigateToBranch]='true'
                [showNavigateToPaidEducationalLeaveAttest]='true'
                [showNavigateToTraining]='true'
                tabindex="0"
              ></alimento-ipv-frontend-communication-card>
            </ng-template>
          </lib-search>
        </div>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate='header'>
        <span class='text-xl p-tabview-title'>{{'communications.all' | translate}}</span>
        <span></span>
      </ng-template>
      <ng-template pTemplate="content">
        <alimento-ipv-frontend-complete-task-dialog
          #completeTaskAllCommunicationDialog
          (submitPopup)='completeTasks($event, completeTaskAllCommunicationDialog, allCommunications)'>
        </alimento-ipv-frontend-complete-task-dialog>
        <div class='my-4'>
          <lib-search #allCommunications
                      [data$]='searchData$'
                      [filters]='searchFilters'
                      [cardTemplate]='cardTemplate'
                      [showToggle]='true'
                      [isToggled]='true'
                      [beforeSearchRequest]='beforeSearchRequest'
                      name='all-communications'
                      toggleLabel='communications.toggle'
                      searchPlaceholderKey='communications.searchPlaceholder'
                      availableResultsTranslateKey='communications.menuTitle'
          >
            <ng-template #cardTemplate let-communication="item">
              <alimento-ipv-frontend-communication-card
                [readOnly]='readOnly'
                [communication]='communication'
                (completeTaskClicked)='completeTaskAllCommunicationDialog.open($event)'
                [showNavigateToBranch]='true'
                [showNavigateToPaidEducationalLeaveAttest]='true'
                [showNavigateToTraining]='true'
                tabindex="0"
              ></alimento-ipv-frontend-communication-card>
            </ng-template>
          </lib-search>
        </div>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, map, Observable, Subscription } from 'rxjs';
import {
  FEATURE,
  FeatureFlagService,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  Role,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { TrainingSearchItem } from '../../../types/training.type';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { SearchesService } from '../../../services/searches.service';
import { TranslateService } from '@ngx-translate/core';
import { TRAINING_SORT_FIELDS } from '../../../types/training.enum';
import { Router } from '@angular/router';
import { PupilTrainingTypeKey, TeacherTrainingTypeKey, TRAINING_TYPE } from '../../../types/reference-data.enum';

@Component({
  selector: 'alimento-ipv-frontend-training-list',
  templateUrl: './training-list.component.html'
})
export class TrainingListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<TrainingSearchItem>;
  searchFilters: SearchFilter[];
  sortFilters: IFilter[];

  addButtonItems: any[];

  protected readonly FEATURE = FEATURE;
  protected readonly Role = Role;
  private _subscription: Subscription;

  constructor(private searchesService: SearchesService,
              private referenceDataService: ReferenceDataService,
              private translateService: TranslateService,
              private translationService: TranslationService,
              private featureService: FeatureFlagService,
              private router: Router) {
  }

  ngOnInit(): void {
    this._subscription = this.translationService.languageChange$.subscribe(() => {
      this._createSearch();
      this.featureService.initialize().subscribe(() => {
        this._createButtonItems();
      });
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: [],
      sortField: TRAINING_SORT_FIELDS.ALIMENTO_ID,
      sortOrder: 0
    };

    this.searchData$ = new InfiniteScrollDataAdapter<TrainingSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<TrainingSearchItem>> => {
      return this.searchesService.searchTrainings(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.status',
        key: FilterType.filterStatuses,
        expanded: true,
        data: this.referenceDataService.getTrainingStatuses()
          .pipe(map(types => types.map(type =>
            ({ type: FilterType.filterStatuses, label: type.label, value: type.data }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterType,
        expanded: true,
        data: this.referenceDataService.getTrainingTypes()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterType,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.published',
        expanded: true,
        key: FilterType.filterIsPublished,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterIsPublished)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.RUBRICS, FilterType.filterRubric)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.toValidateEnrollmentRequests',
        key: FilterType.filterEnrollmentrequestsToValidate,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterEnrollmentrequestsToValidate)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.salesInvoicesToApprove',
        key: FilterType.filterSalesInvoicesToApprove,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterSalesInvoicesToApprove)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.recognizedFor',
        key: FilterType.filterTrainingRecognitionTypes,
        data: this.referenceDataService.getReferenceDataAsFilter(OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES, FilterType.filterTrainingRecognitionTypes)
      },
    ];

    this.sortFilters = [
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.alimentoId'),
        value: TRAINING_SORT_FIELDS.ALIMENTO_ID
      },
      {
        type: 'sort',
        label: this.translateService.instant('trainings.sort.startDate'),
        value: TRAINING_SORT_FIELDS.START_DATE
      }
    ];
  }

  private _createButtonItems(): void {
    this.referenceDataService.getTrainingTypes(true).pipe(first())
      .subscribe(trainingTypes => {
        this.addButtonItems = [
          {
            label: trainingTypes.filter(type => type.data === TRAINING_TYPE.OPEN_TRAINING)[0].label,
            command: () => this.router.navigate(['trainings', 'open', 'create'])
          },
          {
            label: trainingTypes.filter(type => type.data === TRAINING_TYPE.CEVORA)[0].label,
            command: () => this.router.navigate(['trainings', 'cevora', 'create'])
          }
        ];

        if (this.featureService.featureOn(FEATURE.EDUCATIONAL_TRAINING)) {
          [
            TRAINING_TYPE.EDUCATION_EVENT,
            TRAINING_TYPE.EDUCATION_COMPANY_VISIT,
            TRAINING_TYPE.EDUCATION_INFO_SESSION,
            TRAINING_TYPE.EDUCATION_INITIATIVE
          ]
            .forEach(educationType => {
              this.addButtonItems.push({
                label: trainingTypes.filter(type => type.data === educationType)[0].label,
                command: () => this.router.navigate(['trainings', 'training-event', 'create'], {
                  queryParams: {
                    type: educationType
                  }
                })
              });
            });

          this.addButtonItems.push({
            label: trainingTypes.filter(type => type.data === PupilTrainingTypeKey)[0].label,
            command: () => this.router.navigate(['trainings', 'training-project-pupil', 'create'])
          });
          this.addButtonItems.push({
            label: trainingTypes.filter(type => type.data === TeacherTrainingTypeKey)[0].label,
            command: () => this.router.navigate(['trainings', 'training-project-teacher', 'create'])
          });
        }
      });
  }
}

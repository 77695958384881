import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { PaidEducationalLeaveAttestListItem } from '../../../types/paid-educational-leave.type';
import { first } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-card',
  templateUrl: './paid-educational-leave-card.component.html'
})
export class PaidEducationalLeaveCardComponent implements OnChanges {

  @Input()
  paidEducationalLeaveAttest: PaidEducationalLeaveAttestListItem;

  @Input()
  selected = false;

  @Input()
  clickable = false;

  type: string;
  workStatus: string;
  status: string;
  educationalLeaveAttestTitle: string;

  constructor(private referenceDataService: ReferenceDataService,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paidEducationalLeaveAttest']?.currentValue) {
      this._setReadOnlyValues();
    }
  }

  onClick(event: MouseEvent): void {
    event.stopImmediatePropagation();

    if (event?.ctrlKey) {
      window.open(`/paid-educational-leaves/${this.paidEducationalLeaveAttest.id}`, "_blank");
    }
    else {
      this.router.navigate(["/paid-educational-leaves", this.paidEducationalLeaveAttest.id]);
    }
  }

  private _setReadOnlyValues(): void {
    this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.typeId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES)
      .pipe(first())
      .subscribe(type => this.type = type.label);

    this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.employmentWorkStatusId, OPTIONS_LIST_TYPE.WORK_STATUSES)
      .pipe(first())
      .subscribe(workStatus => this.workStatus = workStatus.label);

    this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.stateId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_STATUSES)
      .pipe(first())
      .subscribe(status => this.status = status.label);

    this.referenceDataService.getReferenceDataItem(this.paidEducationalLeaveAttest.educationalLeaveTitleId, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TITLES)
      .pipe(first())
      .subscribe(title => this.educationalLeaveAttestTitle = title.label);
  }
}

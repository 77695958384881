import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponent } from '@alimento-ipv-frontend/ui-lib';
import {
  PaidEducationalLeaveAttest,
  PaidEducationalLeaveContactEvent
} from '../../../types/paid-educational-leave.type';
import { Branch, ContactPerson } from '../../../types/branch.type';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave',
  templateUrl: './paid-educational-leave.component.html',
  providers: [
    { provide: FormComponent, useExisting: PaidEducationalLeaveComponent }
  ]
})
export class PaidEducationalLeaveComponent {
  @Input()
  paidEducationalLeaveAttest: PaidEducationalLeaveAttest;

  @Input()
  contactPersons: ContactPerson[];

  @Input()
  branch: Branch;

  @Output()
  updateContactPerson: EventEmitter<PaidEducationalLeaveContactEvent> = new EventEmitter();

  showMoreAdministrativeRules = false;

  toggleShowMoreAdministrativeRules(): void {
    this.showMoreAdministrativeRules = !this.showMoreAdministrativeRules;
  }

  hasOverflow(element: HTMLDivElement): boolean {
    return element.scrollHeight > element.clientHeight;
  }
}

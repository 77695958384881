import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { PaidEducationalLeaveDetailComponent } from './pages/paid-educational-leave-detail/paid-educational-leave-detail.component';
import { PaidEducationalLeaveComponent } from './components/paid-educational-leave/paid-educational-leave.component';
import { InputTextModule } from 'primeng/inputtext';
import { PaidEducationalLeaveCardComponent } from './components/paid-educational-leave-card/paid-educational-leave-card.component';
import { UtilsModule } from '../utils/utils.module';
import { PaidEducationalLeaveListComponent } from './pages/paid-educational-leave-list/paid-educational-leave-list.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import {
  PaidEducationalLeaveSessionsComponent
} from './components/paid-educational-leave-sessions/paid-educational-leave-sessions.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import {
  PaidEducationalLeaveActionDialogComponent
} from './components/paid-educational-leave-action-dialog/paid-educational-leave-action-dialog.component';
import {
  PaidEducationalLeaveContactDialogComponent
} from './components/paid-educational-leave-contact-dialog/paid-educational-leave-contact-dialog.component';
import { CommunicationsModule } from '../communications/communications.module';
import { Ripple } from 'primeng/ripple';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    InputTextModule,
    UtilsModule,
    InputSwitchModule,
    FormsModule,
    CommunicationsModule,
    Ripple
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: [
    PaidEducationalLeaveDetailComponent,
    PaidEducationalLeaveComponent,
    PaidEducationalLeaveCardComponent,
    PaidEducationalLeaveListComponent,
    StatusChipComponent,
    PaidEducationalLeaveActionDialogComponent,
    PaidEducationalLeaveSessionsComponent,
    PaidEducationalLeaveContactDialogComponent,
  ],
  exports: [
    PaidEducationalLeaveCardComponent
  ]
})
export class PaidEducationalLeaveModule {}

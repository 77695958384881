<div *ngIf='formGroup' [formGroup]='formGroup'>
  <alimento-ipv-frontend-header
    title="{{ 'trainings.basicData.formTitle' | translate }}"
  ></alimento-ipv-frontend-header>
  <h3>{{ 'reimbursementRequests.generalInfoTitle' | translate }}</h3>
  <div class='max-w-[650px]'>
    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      [includeTouchedASInvalid]='false'
      formField='year'
      fieldKey='reimbursementRequests.year'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled || reimbursementRequest.trainingAllowanceApplicationId'>
        {{$any(formGroup.get("year")?.value)?.getFullYear()}}
      </span>
      <lib-date-picker
        *ngIf='formGroup.enabled && !reimbursementRequest.trainingAllowanceApplicationId'
        formControlName="year"
        placeholder='YYYY'
        dateFormat="yy"
        id="year"
        [keepInvalid]='false'
        view="year"
      ></lib-date-picker>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      fieldKey='reimbursementRequests.branch'
      formField='branch'
    >
      <span *ngIf='formGroup.disabled || reimbursementRequest.trainingAllowanceApplicationId' class='read-only-field'>
        {{branch?.branchName}}
      </span>
      <div *ngIf='formGroup.enabled && !reimbursementRequest.trainingAllowanceApplicationId'>
        <alimento-ipv-frontend-branch-select formControlName='branch' id='branch'
        ></alimento-ipv-frontend-branch-select>
      </div>
    </alimento-ipv-frontend-form-field>
  </div>

  <div *ngIf='branch' >
    <div class='bg-grey-0 p-4 mb-8'>
      <div class='grid xl:grid-cols-2 gap-4 mb-4'>
        <div>
          <h4 class='font-bold text-lg'>{{'reimbursementRequests.basicBranchData' | translate}}</h4>
          <div class='grid grid-cols-[minmax(0,_2fr)_minmax(0,_1fr)] gap-4'>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.branchName'>
            <span class='text-primary underline cursor-pointer' [routerLink]='["/branches", branch.branchId, "detail"]'>
              {{branch.branchName}}
            </span>
            </alimento-ipv-frontend-form-field>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.rszCodes'>
              <span class='read-only-field'>{{branch.rszCodes}}</span>
            </alimento-ipv-frontend-form-field>
          </div>
          <div class='grid grid-cols-[minmax(0,_2fr)_minmax(0,_1fr)] gap-4'>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.companyNumber'>
              <span class='read-only-field'>{{branch.companyNumber}}</span>
            </alimento-ipv-frontend-form-field>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.rszNumber'>
              <span class='read-only-field'>{{branch.rszNumber}}</span>
            </alimento-ipv-frontend-form-field>
          </div>
          <div>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.numberOfWorkers'>
              <span class='read-only-field'>{{branch.numberOfWorkersPC118 + branch.numberOfClerksPC220}}</span>
            </alimento-ipv-frontend-form-field>
          </div>
        </div>
        <div>

          <h4 class='font-bold text-lg'>{{'reimbursementRequests.branchAddress' | translate}}</h4>
          <div class='grid grid-cols-[minmax(0,_1fr)_100px_100px] gap-4'>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.street'>
              <span class='read-only-field'>{{branch.street}}</span>
            </alimento-ipv-frontend-form-field>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.houseNumber'>
              <span class='read-only-field'>{{branch.houseNumber}}</span>
            </alimento-ipv-frontend-form-field>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.mailBox'>
              <span class='read-only-field'>{{branch.mailbox}}</span>
            </alimento-ipv-frontend-form-field>
          </div>
          <div class='grid grid-cols-3 gap-4'>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.city'>
              <span class='read-only-field'>{{branch.city}}</span>
            </alimento-ipv-frontend-form-field>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.postalCode'>
              <span class='read-only-field'>{{branch.postalCode}}</span>
            </alimento-ipv-frontend-form-field>
            <alimento-ipv-frontend-form-field [required]='true' fieldKey='branches.country'>
              <span class='read-only-field'>{{branch.country}}</span>
            </alimento-ipv-frontend-form-field>
          </div>
        </div>
      </div>
      <div class='max-w-[650px]'>
        <alimento-ipv-frontend-form-field *ngIf='branch.defaultAccountNumber?.label'
                                          [formGroup]='formGroup'
                                          [required]='true'
                                          fieldKey='reimbursementRequests.accountNumber'
        >
          <span class='read-only-field'>{{branch.defaultAccountNumber.label | accountNumber}}</span>
        </alimento-ipv-frontend-form-field>
      </div>

      <div class='grid lg:grid-cols-2 gap-4' *ngIf='loadingContactPersonsDone'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='contactPerson'
          fieldKey='reimbursementRequests.contactPerson'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{contactPersonsReadOnly[0]}}</span>
          <div *ngIf='formGroup.enabled'>
            <alimento-ipv-frontend-contact-person-select formControlName='contactPerson'
                                                         [contactPersons]='contactPersonsData'
                                                         [showLabels]='false'
                                                         [showBranchInfo]='isGroupContactPerson'
            ></alimento-ipv-frontend-contact-person-select>
          </div>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='contactPerson2'
          fieldKey='reimbursementRequests.contactPerson2'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{contactPersonsReadOnly[1]}}</span>
          <div *ngIf='formGroup.enabled'>
            <alimento-ipv-frontend-contact-person-select formControlName='contactPerson2'
                                                         [contactPersons]='contactPersonsData'
                                                         [showLabels]='false'
                                                         [showBranchInfo]='isGroupContactPerson'
            ></alimento-ipv-frontend-contact-person-select>
          </div>
        </alimento-ipv-frontend-form-field>
      </div>

      <div *ngIf='branch?.administrativeRules'>
        <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                          [required]='true'
                                          fieldKey='branches.administrativeRules'
        >
          <div #content
               class='text-text whitespace-pre-line'
               [ngClass]='{ "h-full overflow-auto": showMoreAdministrativeRules,
                "max-h-[72px] overflow-hidden": !showMoreAdministrativeRules
                }'
               [innerHTML]='branch?.administrativeRules'
          ></div>
          <button *ngIf='showMoreAdministrativeRules || hasOverflow(content)'
                  class='link-button font-bold'
                  (click)="toggleShowMoreAdministrativeRules()">
            {{ (showMoreAdministrativeRules ? 'notes.showLess': 'notes.showMore') | translate }}
          </button>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <alimento-ipv-frontend-training-plans *ngIf='formGroup.get("year").value'
                                          [branchId]='branch.branchId'
                                          [readOnly]='true'
                                          [yearFilter]='formGroup.get("year").value'
    ></alimento-ipv-frontend-training-plans>
  </div>
</div>

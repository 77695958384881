import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CommunicationsMapper } from '../../utils/communications.mapper';
import { MailContactResponse, SearchCommunicationsItem } from '../../../types/communications.type';
import {
  getMailLogItemStateKey,
  getMailLogStateKey,
  MAIL_STATE_FIXED,
  MailContextType,
  MailLogItemState,
  TaskState
} from '../../../types/communications.enum';
import { first } from 'rxjs';
import { TrainingService } from '../../../services/training.service';
import { TrainingMapper } from '../../../utils/mapper/training.mapper';

@Component({
  selector: 'alimento-ipv-frontend-communication-card',
  templateUrl: './communication-card.component.html'
})
export class CommunicationCardComponent implements OnChanges {

  @Input()
  communication: SearchCommunicationsItem;

  @Input()
  showNavigateToTraining = false;

  @Input()
  showNavigateToBranch = false;

  @Input()
  showNavigateToPaidEducationalLeaveAttest = false;

  @Input()
  readOnly = true;

  @Output()
  completeTaskClicked: EventEmitter<SearchCommunicationsItem> = new EventEmitter<SearchCommunicationsItem>();

  actions: MenuItem[];

  constructor(private translateService: TranslateService,
              private trainingService: TrainingService,
              private mapper: CommunicationsMapper,
              private trainingMapper: TrainingMapper,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['communication']?.currentValue) {
      this._setActions();
    }
  }

  getBCC(): MailContactResponse[] {
    return this.communication?.bcc
      .filter(bcc => bcc.email?.toLowerCase() !== 'dalimail@alimento.be' || bcc.linkedTask)
      || [];
  }

  private _setActions(): void {
    this.actions = [];
    for (const context of this.communication.context || []) {
      if (this.showNavigateToTraining && context.typeId === MailContextType.Training) {
        this.actions.push({
          label: this.translateService.instant("communications.navigateToTraining"),
          command: () => {
            this.trainingService.getSimpleTraining(context.id).pipe(first())
              .subscribe((training) => {
                const path = this.trainingMapper.getNavigationPath(training.trainingId, training.typeId);
                this.router.navigate(path);
              });
          }
        });
      }
      else if (this.showNavigateToBranch && context.typeId === MailContextType.Branch) {
        this.actions.push({
          label: this.translateService.instant("communications.navigateToBranch"),
          command: () => this.router.navigate(['/branches', context.id, "detail"])
        });
      }
      else if (this.showNavigateToPaidEducationalLeaveAttest && context.typeId === MailContextType.PaidEducationalLeave) {
        this.actions.push({
          label: this.translateService.instant("communications.navigateToPaidEducationalLeave"),
          command: () => this.router.navigate(['/paid-educational-leaves', context.id])
        });
      }
    }

    if (this._hasLinkedTask() && !this.readOnly) {
      this.actions.push({
        label: this.translateService.instant("communications.completeTasks"),
        command: () => {
          this.completeTaskClicked.emit(this.communication);
        }
      });
    }
  }

  getIcon(mailContactResponse: MailContactResponse): string {
    return this.mapper.getMailLogItemStateIcon(mailContactResponse.statusId);
  }

  getColor(mailContactResponse: MailContactResponse): string {
    return this.mapper.getMailLogItemColor(mailContactResponse.statusId, mailContactResponse.linkedTask?.statusId);
  }

  getMailColor(): string {
    return this.mapper.getMailLogColor(this.communication.statusId, this.communication.linkedTask?.statusId);
  }

  getMailIcon(): string {
    return this.mapper.getMailLogStateIcon(this.communication.statusId);
  }

  private _hasLinkedTask(): boolean {
    if (this.communication.linkedTask?.statusId === TaskState.Open) {
      return true;
    }

    return [...this.communication.to, ...this.communication.cc, ...this.communication.bcc]
      .filter(contact => contact.linkedTask?.statusId === TaskState.Open).length > 0
  }

  getState(item: MailContactResponse): string {
    if (item.linkedTask?.statusId === TaskState.Complete) {
      return MAIL_STATE_FIXED;
    }
    return getMailLogItemStateKey(item.statusId);
  }

  getMailState(): string {
    if (this.communication.linkedTask?.statusId === TaskState.Complete) {
      return MAIL_STATE_FIXED;
    }
    return getMailLogStateKey(this.communication.statusId);
  }

  showStatus(item: MailContactResponse, linkedTask: any): boolean {
    return !!(item?.statusId === MailLogItemState.Failed || linkedTask);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { delay, Subscription, Unsubscribable } from 'rxjs';
import { InfiniteScrollDataAdapter } from '../../utils/InfiniteScrollDataAdapter';
import { ScrollTrackerDirective } from '../../utils/scroll-tracker.directive';

enum View {
  grid = 'grid',
  list = 'list'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-card-view',
  templateUrl: './card-view.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CardViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  data$!: InfiniteScrollDataAdapter<any>;
  @Input()
  cardTemplate: TemplateRef<any>;
  @Input()
  noMoreTemplate: TemplateRef<any>;
  @Input()
  loadMoreString: string;
  @Input()
  availableResultsTranslateKey = 'availableResults';
  @Input()
  deletable = false;
  @Input()
  selectable = false;
  @Input()
  canChangeView = false;
  @Input()
  viewMode: string;
  @Input()
  loadOnScroll = false;
  @Input()
  scrollWindow: any;
  @Input()
  showFilterInfo = true;
  @Input()
  showSearching = true;
  @Input()
  skeletonTemplate: TemplateRef<any>;
  @Input()
  multiselect = true;
  @Input()
  selectedItems: any[] = [];
  @Input()
  idField?: string;
  @Input()
  autoSelectFirstResult = false;
  @Input()
  showCheckboxes = false;

  @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onSelectChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ViewChild("scrollTracker")
  scrollTracker: ScrollTrackerDirective;

  viewOptions = [
    { icon: 'pi pi-bars', view: View.list },
    { icon: 'pi pi-th-large', view: View.grid }
  ];
  selectedView: FormControl;
  currentView: View;

  data: any[];

  private _viewListener: Subscription;
  private previousView: View;
  private _previousInitialSearch = true;
  private _initialSearchSub: Subscription;
  private _dataSubscription: Unsubscribable;
  viewGuid = Math.random() + '';

  ngOnDestroy(): void {
    this._viewListener?.unsubscribe();
    this._initialSearchSub?.unsubscribe();
    this._dataSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.currentView = this.currentView || View.list;
    if (this.canChangeView && localStorage.getItem('view_mode')) {
      this.currentView = View[localStorage.getItem('view_mode') as keyof typeof View];
    }
    this.selectedView = new FormControl(this.viewOptions.find(a => a.view === this.currentView));
    this._viewListener = this.selectedView.valueChanges.subscribe(a => {
      this.changeView(a.view);
      delete this.previousView;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewMode']) {
      if (this.viewMode) {
        this.previousView = this.currentView;
        this.changeView(this.viewMode as View);
      }
      else if (this.previousView) {
        this.changeView(this.previousView);
      }
    }

    if (changes['data$']?.currentValue && this.autoSelectFirstResult) {
      this._addAutoSelectFirstResult();
    }

    if (changes["data$"]) {
      this._dataSubscription?.unsubscribe();
      this._dataSubscription = this.data$?.subscribe({
        next: (data) => {
          this.data = data;
          setTimeout(() => {
            this.scrollTracker?.resetEmitted();
            this.scrollTracker?.onScroll();
          });
        }
      });
    }
  }

  private _addAutoSelectFirstResult(): void {
    this._initialSearchSub?.unsubscribe();
    this._initialSearchSub = this.data$.initialSearch$.pipe(delay(1)).subscribe(isInitialSearch => {
      if (isInitialSearch === false && this._previousInitialSearch === true) {
        this.clearSelection();
        if (this.data$.loadedCount$.value > 0) {
          const sub = this.data$.subscribe({
            next: data => this.selectItem(data[0]),
          });
          sub.unsubscribe();
        }
        else {
          this.selectItem();
        }
      }
      this._previousInitialSearch = isInitialSearch;
    });
  }

  changeView(view: View) {
    this.currentView = view;
    localStorage.setItem('view_mode', view);
    this.selectedView?.setValue(this.viewOptions.find(a => a.view === view), { emitEvent: false });
  }

  loadMore(): void {
    this.data$.loadMore();
  }

  clearSelection(): void {
    this.selectedItems = [];
  }

  hasSelection(): boolean {
    return this.selectedItems.length > 0;
  }

  isSelected(dataItem: any): boolean {
    if (this.idField) {
      return this.selectedItems.map(item => item[this.idField]).includes(dataItem[this.idField]);
    }
    else {
      return this.selectedItems.includes(dataItem);
    }
  }

  selectItem(dataItem?: any, event?: MouseEvent): void {
    if (this.selectable) {
      if (dataItem) {
        if (this.isSelected(dataItem)) {
          if (this.multiselect) {
            this.selectedItems = this.selectedItems.filter(item => {
              if (this.idField) {
                return item[this.idField] !== dataItem[this.idField];
              }
              else {
                return item !== dataItem;
              }
            });
          }
          else {
            this.selectedItems = [];
          }
        }
        else {
          if (this.multiselect) {
            this.selectedItems.push(dataItem);
          }
          else {
            this.selectedItems = [dataItem];
          }
        }
      }
      this.onSelectChange.emit(this.selectedItems);
      event?.stopImmediatePropagation();
    }
  }

  selectPreviousItem(): void {
    this._changeSelectedItemByIndex((index) => index - 1);
  }

  selectNextItem(): void {
    this._changeSelectedItemByIndex((index) => index + 1);
  }

  private _changeSelectedItemByIndex(indexFunction: ((index: number) => number)): void {
    const currentSelectedItem = this.selectedItems[0];
    this.selectedItems = [];

    const sub = this.data$.subscribe({
      next: (data: any) => {
        let index = data.indexOf(currentSelectedItem);
        const nextIndex = indexFunction(index);
        if (nextIndex > -1 && nextIndex < this.data$.loadedCount$.value) {
          index = nextIndex;
        }
        else if (nextIndex > -1 && nextIndex < this.data$.totalCount$.value) {
          if (!this.data$.loading$.value) {
            this.data$.loadMore();
          }
        }

        this.selectedItems.push(data[index]);
      }
    });
    sub.unsubscribe();
  }

  onScrollFinished() {
    if (this.loadOnScroll && this.data$.hasMore$.getValue()) {
      this.loadMore();
    }
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { PAID_EDUCATIONAL_LEAVE_ATTEST_STATE } from '../../../types/paid-educational-leave.enum';
import { first } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-status-chip',
  templateUrl: './status-chip.component.html'
})
export class StatusChipComponent implements OnChanges {
  @Input()
  status!: PAID_EDUCATIONAL_LEAVE_ATTEST_STATE;

  statusLabel: string;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status']?.currentValue) {
      this.referenceDataService.getReferenceDataItem(this.status, OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_STATUSES).pipe(first())
        .subscribe(status => this.statusLabel = status.label);
    }
  }

  getStatusClass(): string {
    if (this.status === PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.VALIDATED) {
      return 'text-primary border border-primary ';
    }
    else {
      return 'border-grey-3 text-grey-3';
    }
  }

}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';
import { EnterpriseGroup, UpdateEnterpriseGroup } from '../types/enterprise-group.type';
import { ContactPerson } from '../types/branch.type';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseGroupService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getEnterpriseGroup(enterpriseGroupId: string): Observable<EnterpriseGroup> {
    return this.http.get<EnterpriseGroup>(`${this.config.readApiUrl}/enterprisegroups/${enterpriseGroupId}`);
  }

  createEnterpriseGroup(enterpriseGroup: UpdateEnterpriseGroup): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/enterprisegroups`, enterpriseGroup);
  }

  updateEnterpriseGroup(enterpriseGroupId: string, enterpriseGroup: UpdateEnterpriseGroup): Observable<{ id: string }> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/enterprisegroups/${enterpriseGroupId}`, enterpriseGroup);
  }

  deleteEnterpriseGroup(enterpriseGroupId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/enterprisegroups/${enterpriseGroupId}`);
  }

  getContactPersons(enterpriseGroupId: string): Observable<ContactPerson[]> {
    return this.http
      .get<ContactPerson[]>(`${this.config.readApiUrl}/enterprisegroups/${enterpriseGroupId}/contactpersons`);
  }
}

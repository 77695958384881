import { Route } from '@angular/router';
import { PaidEducationalLeaveDetailComponent } from './pages/paid-educational-leave-detail/paid-educational-leave-detail.component';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import { PaidEducationalLeaveListComponent } from './pages/paid-educational-leave-list/paid-educational-leave-list.component';

export const paidEducationalLeaveRoutes: Route[] = [
  {
    path: '',
    component: PaidEducationalLeaveListComponent,
    data: { title: 'titles.paidEducationalLeave.list' }
  },
  {
    path: ':paidEducationalLeaveId',
    component: PaidEducationalLeaveDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.paidEducationalLeave.detail' }
  }
];

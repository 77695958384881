<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
></alimento-ipv-frontend-notes-sidebar>

<div [formGroup]="trainingForm" *ngIf='reimbursementRequest()'>
  <alimento-ipv-frontend-detail-page-layout
    [title]="this.training()?.trainingId ? title : 'titles.reimbursementRequests.createTraining' | translate"
    navigateText='reimbursementRequests.toRequest'
    [navigationUrl]="['/reimbursement-requests', this.reimbursementRequestId, 'detail']"
    [navigationQueryParams]='{activeTabIndex: 1}'
    [relatedForm]="trainingForm"
  >
    <ng-container top-action>
      <div class="flex items-center">
        <alimento-ipv-frontend-form-field
          [formGroup]='trainingForm'
          [errorTemplate]='customTitleError'
          formGroupClass='!pb-0'
          formField='customTitle'
          labelClass='hidden'
        >
          <span class='text-xl' *ngIf='readOnly'>{{trainingForm.get("customTitle")?.value}}</span>
          <input
            *ngIf='!readOnly'
            id="customTitle"
            formControlName="customTitle"
            type="text"
            class="min-w-[500px] max-w-[1000px]"
            pInputText
            placeholder="{{ 'trainings.titlePlaceholder' | translate }}"
            data-test="custom-title-input"
            autoWidth
          />
          <ng-template #customTitleError>
            <small *ngIf="trainingForm.get('customTitle')?.hasError('required')">
              {{ 'validation.isRequired' | translate : { field: 'trainings.title' | translate | lowercase } }}
            </small>
            <small *ngIf="trainingForm.get('customTitle')?.hasError('maxlength')">
              {{ 'validation.maxLength' | translate : { number: 200 } }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <span *ngIf="training()?.trainingAlimentoId" class="text-xl mx-2 whitespace-nowrap text-text">
          - {{ training().trainingAlimentoId }}</span>
      </div>

      <div class="flex gap-4" *ngIf='!readOnly'>
        <span
          [pTooltip]="!trainingForm.get('customTitle')?.value ? ('trainings.saveNotPossibleTooltip' | translate) : ''"
        >
          <button
            pButton
            type="button"
            label="{{ 'trainings.actions.save' | translate }}"
            class="secondary-button uppercase"
            icon="pi pi-save"
            loadingIcon="pi pi-spin pi-spinner"
            (click)="saveTraining()"
            [loading]="savingTraining"
            [disabled]="isActivateDisabled"
            data-test="save-training-btn"
          ></button>
        </span>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-training
        [hidden]="activeTabIndex !== 0"
        [reimbursementRequest]='reimbursementRequest()'
        [firstSessionDate]='firstSessionDate'
        [lastSessionDate]='lastSessionDate'
        [contactPersons]='contactPersons'
        [isGroupContactPerson]='isGroupContactPerson'
        [selectedContactPersons]='selectedContactPersons'
        formControlName="basicDataForm"
        #basicTrainingComponent
      >
      </alimento-ipv-frontend-basic-training>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 1"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingType]='TRAINING_TYPE.OPEN_EXTERN'
        [trainingMethodId]='training()?.methodId'
        [defaultTeacherBranchId]='training()?.teacherBranchId'
        [yearOfRequest]='reimbursementRequest().year'
        (sessionUpdated)='loadTrainingData()'
      >
      </alimento-ipv-frontend-sessions>

      <alimento-ipv-frontend-enrollments
        [hidden]="activeTabIndex !== 2"
        [training]="$any(training)"
        [trainingStatus]="trainingStatus"
        [trainingType]="type"
        [groupId]='enterprise()?.enterpriseGroupId'
        [enterpriseId]='enterprise()?.enterpriseId'
        [reimbursementRequest]='reimbursementRequest()'
        [trainingStartDate]='trainingStartDate()'
        (dataChanged)='loadTrainingData()'
      ></alimento-ipv-frontend-enrollments>

      <alimento-ipv-frontend-training-communications
        *ngIf='activeTabIndex === 3'
        [hidden]='activeTabIndex !== 3'
        [readOnly]='readOnly'
        [training]='$any(training())'
      ></alimento-ipv-frontend-training-communications>

      <div [hidden]='activeTabIndex !== 4'>
        <alimento-ipv-frontend-financial-documents-overview [readOnly]='readOnly && financialDocumentsFeatureOn'
                                                            [financialDocuments]='financialDocuments'
                                                            [trainingId]='trainingId'
                                                            [trainingData]='trainingData'
                                                            (changes)='financialDocumentsChanged()'
        ></alimento-ipv-frontend-financial-documents-overview>
      </div>

      <div [hidden]='activeTabIndex !== 5'>
        <alimento-ipv-frontend-reimbursements-overview [readOnly]='readOnly && reimbursementFeatureOn'
                                                       [reimbursements]='reimbursements'
                                                       [trainingId]='trainingId'
                                                       [trainingData]='trainingData'
                                                       [selectedContactPersons]='selectedContactPersons'
                                                       (changes)='reimbursementsChanged()'
        ></alimento-ipv-frontend-reimbursements-overview>
      </div>

      <alimento-ipv-frontend-invoices-overview *ngIf='activeTabIndex === 6'
                                                       [readOnly]='readOnly'
                                                       [trainingId]='trainingId'
                                                       [hasCounselor]='!!training()?.counselorId'
                                                       [purchaseEntries]='purchaseEntries'
                                                       [salesInvoices]='salesInvoices'
                                                       (purchaseEntryChanges)='getPurchaseEntries()'
                                                       (salesInvoiceChanges)='getSalesInvoices()'
      >
      </alimento-ipv-frontend-invoices-overview>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-training-status-chip
        *ngIf='trainingId'
        [status]="getTrainingStatusKey(trainingStatus)"
        [trainingOngoing]="isOngoing()"
        [isCancelled]='false'
      ></alimento-ipv-frontend-training-status-chip>

      <div *ngIf='!readOnly && (trainingStatus === trainingStatusKeys.Cancelled || trainingStatus === trainingStatusKeys.Completed)'
           class='flex flex-col gap-4'>
        <alimento-ipv-frontend-reactivate-training-dialog
          #reactivateTrainingDialog
          [training]='$any(training)'
          [firstSessionDate]='firstSessionDate'
          (reactivateTrainingConfirmed)="reactivateTraining($event)"
        >
        </alimento-ipv-frontend-reactivate-training-dialog>

        <button
          pButton
          pRipple
          type="button"
          label="{{ 'trainings.actions.reactivate' | translate }}"
          class="p-button-primary p-button-outlined uppercase"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="reactivatingTraining"
          (click)="reactivateTrainingDialog.open()"
        ></button>

      </div>

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-close-training-dialog
          #closeTrainingDialog
          [training]='$any(training)'
          [firstSessionDate]='firstSessionDate'
          (closeTrainingConfirmed)="closeTraining($event)"
        >
        </alimento-ipv-frontend-close-training-dialog>

        <button
          *ngIf='trainingStatus === trainingStatusKeys.Active'
          pButton
          pRipple
          type="button"
          label="{{ 'trainings.actions.close' | translate }}"
          class="p-button-primary p-button-outlined uppercase"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="closingTraining"
          (click)="closeTrainingDialog.open()"
        ></button>

        <div *ngIf='sessionService.nrOfSessions() > 0 && enrollmentService.enrollments()?.length > 0'>
          <div class="link-button link-button-with-icon justify-between flex-row-reverse">
            <p-inputSwitch #completeAllAbsences
                           [formControl]="completeAllAbsencesToggle"
            ></p-inputSwitch>
            <span (click)="!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined"
                  (keyup.enter)='!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined'
                  class='focus-visible:outline-0 focus-visible:underline whitespace-nowrap'
                  tabindex='0'
            >
              {{ 'trainings.actions.completeAllAbsences' | translate }}
            </span>
          </div>
        </div>

        <div *ngIf='trainingId'>
          <alimento-ipv-frontend-clone-training-dialog
            [training]='$any(training())'
            [firstSessionDate]='firstSessionDate'
            (cloneTraining)="cloneTraining($event)"
          >
          </alimento-ipv-frontend-clone-training-dialog>
          <alimento-ipv-frontend-move-training-dialog
            [training]='$any(training())'
            [firstSessionDate]='firstSessionDate'
            [groupId]='enterprise()?.enterpriseGroupId'
            (moveTraining)="moveTraining($event)"
          >
          </alimento-ipv-frontend-move-training-dialog>

          <div class="link-button link-button-with-icon justify-between" (click)="extraActionsButtonClicked($event, menu)">
            <span tabindex='0'
                  class='focus-visible:outline-0 focus-visible:underline'
                  (keyup.enter)='extraActionsButtonClicked($event, menu)'>
              {{ 'trainings.actions.extra' | translate }}
            </span>
            <i class='pi pi-chevron-down'></i>
          </div>
          <p-menu #menu [model]="extraActions" [popup]="true" >
            <ng-template pTemplate="item" let-item>
              <a class="p-menuitem-link flex gap-2 justify-between items-center p-3">
                <span>{{ item.label }}</span>
                <span class='font-material text-lg'>{{item.icon}}</span>
              </a>
            </ng-template>
          </p-menu>
        </div>

        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId && !isOngoing()'>
          <div class="cursor-pointer hover:underline focus-visible:outline-0 focus-visible:underline link-button--danger"
               tabindex='0'
               (click)="cancelTrainingDialog.open()"
               (keyup.enter)="cancelTrainingDialog.open()"
               [pTooltip]="isOngoing() ? ('trainings.firstSessionAlreadyOver' | translate) : ''"
          >
            <alimento-ipv-frontend-cancel-training-dialog
              #cancelTrainingDialog
              [training]='$any(training())'
              [firstSessionDate]='firstSessionDate'
              (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"
            >
            </alimento-ipv-frontend-cancel-training-dialog>
            <span>{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}</span>
          </div>
        </div>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>

      <ng-container *ngIf="reasonCancelled">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.reasonCancelled' | translate }}"
          value="{{ reasonCancelled }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.cancelledComment">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.cancelledComment' | translate }}"
          value="{{ training().cancelledComment }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <alimento-ipv-frontend-metadata-item *ngIf="reimbursementRequest()?.year"
                                           title='reimbursementRequests.yearOfReimbursement'
                                           [value]='"" + reimbursementRequest().year'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().totalDuration"
                                           title='trainings.totalDuration'
                                           [value]='trainingData().totalDuration'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().sumTotalAmount"
                                           title='trainings.totalCost'
                                           [value]='trainingData().sumTotalAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().sumPreparationAmount"
                                           title='trainings.totalPreparationCost'
                                           [value]='trainingData().sumPreparationAmount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="trainingData().totalAmountToPayout"
                                           title='trainings.totalAmountToPayout'
                                           [value]='trainingData().totalAmountToPayout | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>

      <ng-container *ngIf="training()?.createdBy">
        <alimento-ipv-frontend-metadata-item
          title="trainings.createdBy"
          [value]="training().createdBy"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdOn">
        <alimento-ipv-frontend-metadata-item
          title="trainings.createdOn"
          [value]="training().createdOn | date : 'dd/MM/yyyy'"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>

<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<div *ngIf="paidEducationalLeaveAttest">
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='paidEducationalLeaveId'
                                       [readonly]='readOnly'
                                       (hasChanges)='notesHasChanges = $event'
                                       [type]='PaidEducationalLeaveNote'>
  </alimento-ipv-frontend-notes-sidebar>

  <alimento-ipv-frontend-detail-page-layout
    title="{{ 'paidEducationalLeaves.menuTitle' | translate }}"
    [navigateText]='"navigateToOverview" | translate'
    [navigationUrl]="['/paid-educational-leaves']"
  >
    <ng-container top-action>
      <div class="text-xl">
        <span class='text-text'>{{ type }}</span>
        <span class="mx-2 whitespace-nowrap text-text">- {{ paidEducationalLeaveAttest.alimentoId }} {{person?.lastName}} {{person?.firstName}}</span>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)='setActiveTabIndex($event)'
        [activeTabIndex]='activeTabIndex'
        [data]='tabMenuItems'
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div *ngIf='activeTabIndex === 0' [hidden]='activeTabIndex !== 0'>
        <div>
          <alimento-ipv-frontend-paid-educational-leave
            [paidEducationalLeaveAttest]='paidEducationalLeaveAttest'
            [contactPersons]='contactPersons'
            [branch]='branch'
            (updateContactPerson)='updateContactPerson($event)'
          ></alimento-ipv-frontend-paid-educational-leave>
        </div>

        <div>
          <h3>{{ 'paidEducationalLeaves.sessions' | translate }}</h3>

          <alimento-ipv-frontend-paid-educational-leave-sessions [paidEducationalLeaveAttest]='paidEducationalLeaveAttest'
                                                                 (sessionUpdateEvent)='sessionUpdateEvent($event)'
          ></alimento-ipv-frontend-paid-educational-leave-sessions>
        </div>

        <div class='mt-2'>
          <alimento-ipv-frontend-page-detail-communications [contextType]='MailContextType.PaidEducationalLeave'
                                                            [contextValue]='paidEducationalLeaveId'
                                                            [readOnly]='readOnly'
                                                            [showFilters]='false'
          ></alimento-ipv-frontend-page-detail-communications>
        </div>
      </div>
    </ng-container>

    <ng-container metadata>

      <alimento-ipv-frontend-paid-educational-leave-status-chip [status]='paidEducationalLeaveAttest.statusId'
      ></alimento-ipv-frontend-paid-educational-leave-status-chip>

      <alimento-ipv-frontend-metadata-item title='paidEducationalLeaves.schoolYear'
                                           [value]='schoolYear'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                           title='paidEducationalLeaves.branches'
                                           [isLink]='true'
                                           [link]='["/branches", branch.branchId, "detail"]'
                                           [value]='branch.branchName'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item *ngIf="person"
                                           title='persons.person'
                                           [isLink]='true'
                                           [link]='["/persons", person.personId, "detail"]'
                                           value='{{person.lastName}} {{person.firstName}}'
      ></alimento-ipv-frontend-metadata-item>
      <alimento-ipv-frontend-metadata-item title='persons.workStatus'
                                           [value]='workStatus'
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>

<div *ngIf="data">
  <div class="flex justify-end mr-1.5" *ngIf="canChangeView">
    <p-selectButton [formControl]="selectedView" [options]="viewOptions" optionLabel="icon"
                    styleClass="viewButtongroup">
      <ng-template let-item pTemplate>
        <i [class]="item.icon"></i>
      </ng-template>
    </p-selectButton>
  </div>
  <div *ngIf="(data$.initialSearch$ | async) === false">
    <div #scrollTracker="scrollTracker"
         class="flex flex-wrap"
         scrollTracker
         [footerHeight]='40'
         (scrollingFinished)="onScrollFinished()"
         [scrollWindow]="scrollWindow">
      <div
        *ngFor="let dataItem of data; let i = index"
        [ngClass]="currentView === 'grid' ? 'lg:w-1/3 w-full' : 'w-full'"
        (mousedown)="showCheckboxes ? undefined : selectItem(dataItem, $event)"
      >
        <div class='flex items-center gap-4'>
          <div *ngIf="selectable && multiselect && showCheckboxes">
            <input
              #elementRef
              (click)="selectItem(dataItem, $event)"
              [attr.index]="i"
              [name]="viewGuid"
              [value]="i"
              [checked]="isSelected(dataItem)"
              class="checkbox-styling !mt-0"
              type="checkbox"
            />
          </div>
          <div class='w-full'>
            <ng-container *ngTemplateOutlet="cardTemplate; context: {
              item: dataItem,
              currentView: currentView,
              selected: isSelected(dataItem),
              deletable: deletable
            }">
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(data$.totalCount$ | async) === 0 && (data$.loadingSlowly$ | async) === false">
    <span>{{"noResults" | translate}}</span>
  </div>

  <div class="my-2" *ngIf="showFilterInfo && (data$.totalCount$ | async) > 0 && (data$.loading$ | async) === false">
    <lib-current-filter-info
      [data$]="data$"
      [availableResultsTranslateKey]="availableResultsTranslateKey"
      [loadOnScroll]="loadOnScroll"
    ></lib-current-filter-info>
  </div>

  <button (click)="loadMore()"
          *ngIf="!loadOnScroll && (data$.hasMore$ | async) && (data$.initialSearch$ | async) === false; else noMoreTemplate"
          [disabled]="(data$.loading$ | async) ? true : null"
          class="w-full primary-button">
    <span *ngIf="(data$.loading$ | async) === false" class="text-white text-lg">
      {{loadMoreString || ("loadMoreSessions" | translate)}}
    </span>

    <span *ngIf="data$.loadingSlowly$ | async" class="inline-flex items-center text-white">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor"></path>
      </svg>
      <span>{{"loading" | translate}}...</span>
    </span>
  </button>
</div>
<div *ngIf="data$ && (!data || (data$.loadingSlowly$ | async))">
  <div *ngIf="currentView === 'grid'" class="w-full flex flex-wrap">
    <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
    <div *ngFor="let i of [].constructor(data$.getCurrentSearchRequest().rows)" class="lg:w-1/3 w-full">
      <div *ngIf="!skeletonTemplate" class="flex flex-col shadow-purple-10 shadow-md m-2 p-4 hover:bg-purple-lighter border border-purple-10">
        <p-skeleton class="mb-4"></p-skeleton>
        <p-skeleton class="mb-4"></p-skeleton>
        <p-skeleton class="mb-4"></p-skeleton>
        <p-skeleton class="mb-4"></p-skeleton>
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </div>
  <div *ngIf="currentView !== 'grid'" class="w-full">
    <div *ngFor="let i of [].constructor(data$.getCurrentSearchRequest().rows)" class="w-full">
      <ng-container [ngTemplateOutlet]="skeletonTemplate"></ng-container>
      <div *ngIf="!skeletonTemplate" class="shadow-purple-10 shadow-md m-2 mb-4 p-4 hover:bg-purple-lighter pr-8 border border-purple-10">
        <div class="flex flex-col w-full p-4">
          <p-skeleton class="mb-4"></p-skeleton>
          <p-skeleton class="mb-4"></p-skeleton>
          <p-skeleton></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

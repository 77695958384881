import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { SearchBarFacetComponent } from '../search-bar-facet/search-bar-facet.component';
import { skip, Subscription, Unsubscribable } from 'rxjs';
import { InfiniteScrollDataAdapter } from '../../../utils/InfiniteScrollDataAdapter';
import { ToggleComponent } from '../../toggle/toggle.component';
import { FileHandlerService } from '../../../services/file-handler.service';
import { DateMapper } from '../../../utils/date.mapper';
import {
  DateFilter,
  FilterType,
  FilterValue, IFilter,
  IFilterEvent,
  ITypeFilter,
  SearchFilter,
  SearchFilterType,
  SearchRequest
} from '../../../types/search.type';
import { SEARCH_FILTER_KEY } from '../../../types/localstorage-constants';
import { SORT_ORDER } from '../../../types/ui-lib.enum';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  name: string;

  @Input()
  filters: SearchFilter[] = [];

  @Input()
  data$: InfiniteScrollDataAdapter<any>;

  @Input()
  idField?: string;

  @Input()
  cardTemplate: TemplateRef<any>;

  @Input()
  noMoreTemplate: TemplateRef<any>

  @Input()
  tableTemplate: TemplateRef<any>;

  @Input()
  loadMoreString: string;

  @Input()
  availableResultsTranslateKey = "availableResults";

  @Input()
  showToggle = false;

  @Input()
  toggleLabel: string;

  @Input()
  isToggled = false;

  @Input()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  beforeSearchRequest: (comp: SearchComponent, request: SearchRequest) => void = () => {
  };

  @Input()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFilterChange: (comp: SearchComponent, event: IFilterEvent) => void = () => {
  };

  @Input()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRemoveFilter: (comp: SearchComponent, event: FilterValue) => void = () => {
  };

  @Input()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleAction: (comp: SearchComponent, toggle: boolean) => void = () => {
  };

  @Input()
  setTodayAsDefault = false;

  @Input()
  defaultFromDate: Date;

  @Input()
  defaultToDate: Date;

  @Input()
  canSelectPastDates = true;

  @Input()
  selectable = false;

  @Input()
  multiselect = false;

  @Input()
  showCheckboxes = false;

  @Input()
  selectedItems: any[] = [];

  @Input()
  deletable = false;

  @Input()
  tabPanelIndex: number;

  @Input()
  tabPanelName: string;

  @Input()
  showActiveFilters = true;

  @Input()
  extraQueryParameters: { [key: string]: string } = {};

  @Input()
  loadOnScroll = true;

  @Input()
  scrollWindow: any;

  @Input()
  showSearchFilter = true;

  @Input()
  exportUrl: string;

  @Input()
  exportFileName = "export";

  @Input()
  useFileNameFromResponse = true;

  @Input()
  searchPlaceholderKey: string;

  @Input()
  expandAll = true;

  @Input()
  numberOfItems = 9;

  @Input()
  canChangeView = false;

  @Input()
  activeFilters: { [key: string]: string[] } = {};

  @Input()
  autoFocus = true;

  @Input()
  autoSelectFirstResult = false;

  @Input()
  refreshOnToggleChange = true;

  @Input()
  saveFilters = true;

  @Input()
  inlineFilters = false;

  @Input()
  activeFiltersOnTop = true;

  @Input()
  sortItems: IFilter[] = [];

  @Input()
  showCountTop = true;

  @Input()
  addSelectAll = false;

  @Input()
  maxSelectAll = 9999;

  @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onSelectChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  search: EventEmitter<void> = new EventEmitter<void>();

  searchValue = "";
  sortValue = "";
  dateFilter: DateFilter = {fromDate: undefined, toDate: undefined};
  expanded: { [key: string]: boolean } = {};
  initialToggleValue = false;
  exportLoad = false;
  filtersHaveChanged = false;
  isMobile = false;

  @ViewChild(SearchBarFacetComponent)
  searchBar: SearchBarFacetComponent;

  @ViewChild("toggleComponent")
  toggleComponent: ToggleComponent;

  private _breakpointSub?: Subscription;
  private _init = false;
  readonly SEARCH_FILTER_TYPE = SearchFilterType;
  private _selectAllSub: Unsubscribable;

  constructor(private fileHandler: FileHandlerService) {
  }

  ngOnInit(): void {
    if (this.saveFilters) {
      const params = JSON.parse(localStorage.getItem(SEARCH_FILTER_KEY + this.name) || '{}');
      this.filtersHaveChanged = params["changed"] === "true"|| params["changed"] === "1" ;

      Object.values(FilterType).forEach(filter => {
        if (params[filter]) {
          this.activeFilters[filter] = Array.isArray(params[filter]) ? params[filter] : [params[filter]];
        }
        this.expanded[filter] = this.expandAll || this.activeFilters[filter]?.length > 0;
      });

      this.searchValue = params["q"] || undefined;
      this.sortValue = params['sort'] || this._getDefaultSortFilter();
      this.dateFilter = {
        fromDate: params["from"],
        toDate: params["to"]
      };

      if (params["isToggled"] != undefined) {
        this.initialToggleValue = params["isToggled"] === "true" || params["isToggled"] == 1;
      }
      else if (!this.filtersHaveChanged) {
        this.initialToggleValue = this.isToggled;
      }

      this.isToggled = this.initialToggleValue;
      this.toggleEvent(this.isToggled, true);
    }

    this._setDefaultDateFilters();

    if (this.data$) {
      this.refresh();
    }

    this._init = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']?.currentValue) {
      this.filters.forEach(filter => {
        if (this.expandAll || filter.expanded) {
          this.expanded[filter.key] = true;
        }
      });
    }

    if (changes['data$']?.currentValue && this._init) {
      this.refresh();
    }

    if (changes['defaultFromDate']?.currentValue || changes['defaultToDate']?.currentValue) {
      this._setDefaultDateFilters();
      this.filterOnDate({
        fromDate: this.dateFilter.fromDate,
        toDate: this.dateFilter.toDate
      });
    }
  }

  refresh(): void {
    this._updateUrlParamsAndSearch();
  }

  onSearch(searchValue: string): void {
    this.searchValue = searchValue;
    this.filtersHaveChanged = true;
    this._updateUrlParamsAndSearch();
  }

  onSort($event: string) {
    this.sortValue = $event;
    this._updateUrlParamsAndSearch();
  }

  filterChanged(event: IFilterEvent): void {
    this.filtersHaveChanged = true;
    this.onFilterChange(this, event);
    this.activeFilters[event.filter] = event.values;
    this._updateUrlParamsAndSearch();
  }

  private _getSearchRequest(): SearchRequest {
    const searchRequest = {} as SearchRequest;
    searchRequest.first = 0;
    searchRequest.rows = this.numberOfItems;

    if (this.sortValue) {
      searchRequest.sortField = this.sortValue.split("_")[0];
      searchRequest.sortOrder = this.sortValue.split("_")[1] === SORT_ORDER.ASC ? 1 : 0;
    }

    const filters: ITypeFilter[] = [];
    Object.keys(this.activeFilters).forEach(filter => {
      const activeFilter = this.activeFilters[filter];
      if (activeFilter?.length > 0) {
        filters.push({type: filter, values: activeFilter});
      }
    });

    if (this.dateFilter.fromDate) {
      filters.push({type: FilterType.fromDate, values: [this.dateFilter.fromDate]})
    }
    if (this.dateFilter.toDate) {
      filters.push({type: FilterType.toDate, values: [this.dateFilter.toDate]})
    }
    if (this.searchValue) {
      filters.push({type: FilterType.search, values: [this.searchValue]})
    }

    searchRequest.filters = filters;
    this.beforeSearchRequest(this, searchRequest);

    return searchRequest;
  }

  setUrlParameters(): void {
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    const queryParams = JSON.parse(JSON.stringify(this.activeFilters));
    if (this.searchValue) {
      queryParams.q = this.searchValue;
    }
    if (this.sortValue) {
      queryParams.sort = this.sortValue;
    }
    if (this.dateFilter.fromDate) {
      queryParams.from = this.dateFilter.fromDate;
    }
    if (this.dateFilter.toDate) {
      queryParams.to = this.dateFilter.toDate;
    }

    if (this.tabPanelIndex !== undefined) {
      queryParams.tabIndex = this.tabPanelIndex;
    }

    if (this.tabPanelName !== undefined) {
      queryParams.tabName = this.tabPanelName;
    }

    if (this.showToggle && this.isToggled !== undefined) {
      queryParams.isToggled = this.isToggled ? 1 : 0;
    }

    if (this.filtersHaveChanged) {
      queryParams.changed = 1;
    }

    Object.keys(this.extraQueryParameters).forEach(key => queryParams[key] = this.extraQueryParameters[key]);

    if (this.saveFilters) {
      localStorage.setItem(SEARCH_FILTER_KEY + this.name, JSON.stringify(queryParams));
    }
  }

  private _updateUrlParamsAndSearch(): void {
    this.setUrlParameters();
    this.data$?.query(this._getSearchRequest());
    this.search.emit();
  }

  ngOnDestroy(): void {
    this._breakpointSub?.unsubscribe();
    this._selectAllSub?.unsubscribe();
  }

  filterOnDate(event: DateFilter): void {
    this.dateFilter = event;
    this.filtersHaveChanged = true;
    this._updateUrlParamsAndSearch();
  }

  removeFilter(event: FilterValue): void {
    if (this.activeFilters[event.type]) {
      this.activeFilters[event.type] = this.activeFilters[event.type].filter(value => value != event.value);
    }
    else if (this.dateFilter[event.type]) {
      this.dateFilter = {...this.dateFilter};
      this.dateFilter[event.type] = undefined;
    }
    else if (event.type === FilterType.search) {
      this.searchValue = "";
    }
    this.onRemoveFilter(this, event);
    this._updateUrlParamsAndSearch();
  }

  removeAllFilters(): void {
    this.filtersHaveChanged = false;
    this.activeFilters = {};
    this.searchValue = "";
    if (this.inlineFilters) {
      this.dateFilter = {
        fromDate: undefined,
        toDate: undefined
      }
    }
    else {
      this.dateFilter = {
        fromDate: this.setTodayAsDefault ? DateMapper.getDateFromDateTimeAsString(new Date()) :
          this.defaultFromDate ? DateMapper.getDateFromDateTimeAsString(this.defaultFromDate) : undefined,
        toDate: this.defaultToDate ? DateMapper.getDateFromDateTimeAsString(this.defaultToDate) : undefined,
      }
    }
    this._updateUrlParamsAndSearch();
  }

  toggleEvent(event: boolean, initial = false) {
    if (this.initialToggleValue !== event) {
      this.filtersHaveChanged = true;
    }
    this.isToggled = event;
    if (this.filters) {
      this.setUrlParameters();
      this.toggleAction(this, event);
      if (!initial && this.refreshOnToggleChange) {
        this.refresh();
      }
    }
  }

  setToggleValue(newValue: boolean): void {
    this.toggleComponent.changeValue(newValue, false);
  }

  selectChange(event: any[]) {
    this.onSelectChange.emit(event);
  }

  export(): void {
    this.exportLoad = true;
    const data = JSON.parse(JSON.stringify(this.data$.getCurrentSearchRequest()));
    delete data.limit;
    delete data.offset;
    this.fileHandler.downloadFile(this.exportUrl, data, this.exportFileName).subscribe({
      next: () => this.exportLoad = false,
      error: () => this.exportLoad = false
    });
  }

  setFocus(): void {
    this.searchBar.setFocus();
  }

  removeSelection(): void {
    this.selectedItems = [];
    this.selectChange(this.selectedItems);
  }

  private _setDefaultDateFilters(): void {
    if (!this.filtersHaveChanged && this.setTodayAsDefault && !this.dateFilter.fromDate) {
      this.dateFilter.fromDate = DateMapper.getDateFromDateTimeAsString(new Date());
    }
    else if (!this.filtersHaveChanged && this.defaultFromDate && !this.dateFilter.fromDate) {
      this.dateFilter.fromDate = DateMapper.getDateFromDateTimeAsString(this.defaultFromDate);
    }

    if (!this.filtersHaveChanged && this.defaultToDate && !this.dateFilter.toDate) {
      this.dateFilter.toDate = DateMapper.getDateFromDateTimeAsString(this.defaultToDate);
    }
  }

  private _getDefaultSortFilter(): string {
    const currentSearchRequest = this.data$.getInitialSearchRequest();
    if (currentSearchRequest?.sortField) {
      return currentSearchRequest.sortField + "_" + (currentSearchRequest.sortOrder === 1 ? SORT_ORDER.ASC : SORT_ORDER.DESC);
    }
    return undefined;
  }

  selectAll(): void {
    this._selectAllSub = this.data$.subscribe({
      next: data => {
        this.selectedItems = [...data];
        this._selectAllSub.unsubscribe();
        this.selectChange(this.selectedItems);
      }
    }, skip(1));
    this.data$.loadMore(0, this.maxSelectAll);
  }
}

<alimento-ipv-frontend-paid-educational-leave-action-dialog [action]='currentAction'
                                                          (submitPopup)='executeAction($event)'
></alimento-ipv-frontend-paid-educational-leave-action-dialog>

<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="uppercase">{{ 'paidEducationalLeaves.menuTitle' | translate }}</h1>
    <div class='flex items-center gap-2'>
      <p-button
        *ngIf='canCommunicate'
        (click)='openActionDialog(PAID_EDUCATION_LEAVE_ACTION.ATTEST_REQUESTED)'
        label="{{ 'paidEducationalLeaves.actions.attestRequested' | translate }}"
        styleClass="uppercase"
      ></p-button>
<!--      <p-button-->
<!--        *ngIf='canApprove'-->
<!--        (click)='openActionDialog(PAID_EDUCATION_LEAVE_ACTION.CREATE_AND_SEND)'-->
<!--        label="{{ 'paidEducationalLeaves.actions.createAndSend' | translate }}"-->
<!--        styleClass="uppercase"-->
<!--      ></p-button>-->
    </div>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              [selectable]='true'
              [expandAll]='false'
              [multiselect]='true'
              [showCheckboxes]='true'
              [addSelectAll]='true'
              [maxSelectAll]='100'
              idField='alimentoId'
              name='paid-educational-leaves'
              searchPlaceholderKey='paidEducationalLeaves.searchPlaceholder'
              availableResultsTranslateKey='paidEducationalLeaves.certificates'
              (onSelectChange)='onselectionchange($event)'
  >
    <ng-template #cardTemplate let-item="item" let-selected="selected">
      <alimento-ipv-frontend-paid-educational-leave-card
        [paidEducationalLeaveAttest]='item'
        [selected]='selected'
        [clickable]='true'
        tabindex="0"
      ></alimento-ipv-frontend-paid-educational-leave-card>
    </ng-template>
  </lib-search>
</div>

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { PaidEducationalLeaveContactEvent } from '../../../types/paid-educational-leave.type';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BranchService } from '../../../services/branch.service';
import { ContactPerson } from '../../../types/branch.type';
import { first } from 'rxjs';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';

@Component({
  selector: 'alimento-ipv-frontend-paid-educational-leave-contact-dialog',
  templateUrl: './paid-educational-leave-contact-dialog.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PaidEducationalLeaveContactDialogComponent implements OnChanges {

  @Input()
  branchId: string;

  @Output()
  submitPopup = new EventEmitter<PaidEducationalLeaveContactEvent>();

  dialogVisible = false;
  loading = false;
  formGroup: FormGroup;
  contactPersons: ContactPerson[];

  constructor(private formBuilder: FormBuilder,
              private branchService: BranchService,
              private messageService: MyMessageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branchId']?.currentValue) {
      this.branchService.getContactPersons(this.branchId).pipe(first())
        .subscribe(contactPersons => {
          this.contactPersons = contactPersons;
        });
    }
  }

  open(): void {
    this.dialogVisible = true;
    this._createFormGroup();
  }

  private _createFormGroup() {
    this.formGroup = this.formBuilder.group({
      contactPersonId: [undefined, Validators.required]
    });
  }

  onSubmit(): void {
    validateAllFormFields(this.formGroup);
    if (!this.formGroup.valid) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.submitPopup.emit({
      contactPersonId: this.formGroup.value.contactPersonId,
      branchId: this.branchId,
      setLoading: (value: boolean) => (this.loading = value),
      closeDialog: () => this.closeDialog()
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }
}
